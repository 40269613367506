import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { ModalBody, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import { ArticleHeader2 } from '../../../components/parent/article-header'
import ArticleBGWrapper from '../../../components/parent/article-bg-wrapper'
import UsefulTools from '../../../components/parent/useful-tools'
import {
  CuriosityCloseButton,
  CuriosityModal,
} from '../../../components/parent/curiosity'

import { ArticleWrapper, ArticleContainer } from '../../../components/elements'

const ExpertWrapper = styled.div`
  margin-bottom: 45px;

  .gatsby-image-wrapper {
    height: auto;
    max-width: 125px;
  }

  p {
    font-weight: 400;
    margin-top: 5px;
  }
`

export const Expert = ({ image, name }) => (
  <ExpertWrapper>
    <Img fluid={image} alt={name} />
    <p>{name}</p>
  </ExpertWrapper>
)

const Page = ({ location }) => {
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/payback/article2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/krzyzowki.png" }) {
        childImageSharp {
          fluid(maxWidth: 1390) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/kolorowanka.png" }) {
        childImageSharp {
          fluid(maxWidth: 1390) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool3: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, maxHeight: 1342) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImage: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImageMobile: file(
        relativePath: { eq: "parent/curiosity/MOBILE/mobile_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Expert: file(relativePath: { eq: "parent/payback/expert2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 125) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Czy dawanie kieszonkowego się opłaca? Tatiana Mindewicz-Puacz"
        description="Mogłoby się wydawać, że dzieci i pieniądze to dwa odległe światy. Jeśli łączymy je w jakikolwiek sposób to najczęściej wtedy, kiedy uświadamiamy sobie, ile nas kosztuje utrzymanie dziecka lub kiedy komentujemy zgłaszane przez dziecko potrzeby finansowe."
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader2
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        title={<>Czy dawanie kieszonkowego się opłaca?</>}
        linkPrev="/strefa-rodzica/kieszonkowe"
      />

      <ArticleBGWrapper>
        <ArticleWrapper style={{ marginTop: 0, fontWeight: 300 }}>
          <ArticleContainer>
            <Row>
              <Col xs={12} className="marginTop30">
                <Expert
                  image={data.Expert.childImageSharp.fluid}
                  name="Tatiana Mindewicz-Puacz"
                />

                <p>
                  Mogłoby się wydawać, że dzieci i{'\u00A0'}pieniądze to
                  {'\u00A0'}dwa odległe światy. Jeśli łączymy je{'\u00A0'}w
                  jakikolwiek sposób, to{'\u00A0'}najczęściej wtedy, kiedy
                  uświadamiamy sobie, ile nas kosztuje utrzymanie dziecka, lub
                  kiedy komentujemy zgłaszane przez dziecko potrzeby finansowe.{' '}
                </p>
                <p>
                  Większość dyskusji na{'\u00A0'}temat kieszonkowego ogranicza
                  się do{'\u00A0'}jego wysokości i{'\u00A0'}tego, na{'\u00A0'}co
                  naszym zdaniem należy je{'\u00A0'}przeznaczyć. Choć oczekujemy
                  od{'\u00A0'}naszych dzieci odpowiedzialnego stosunku do
                  {'\u00A0'}pieniędzy, marzymy o{'\u00A0'}tym, żeby były zaradne
                  i{'\u00A0'}samodzielne – mamy trudność w{'\u00A0'}przekazaniu
                  im{'\u00A0'}wiedzy, która jest niezbędna do{'\u00A0'}tego,
                  żeby takie umiejętności nabyć. A{'\u00A0'}przecież nie od
                  {'\u00A0'}dzisiaj wiadomo, że najlepszym sposobem przyswajania
                  wiedzy jest doświadczenie.
                </p>
                <p>
                  Jestem zdania, że warto traktować KIESZONKOWE jako cenne
                  narzędzie do{'\u00A0'}trenowania niezwykle przydatnych życiowo
                  kompetencji. Nie tylko tych ściśle związanych z{'\u00A0'}
                  finansami, ale też z{'\u00A0'}komunikacją, odpowiedzialnością,
                  podejmowaniem ryzyka, ponoszeniem konsekwencji, a{'\u00A0'}
                  nawet empatią (poprzez uwrażliwienie na{'\u00A0'}sytuację
                  innych, dzielenie się).{' '}
                </p>
                <p>
                  Zachowanie dobrych proporcji pomiędzy przyzwoleniem na
                  {'\u00A0'}samodzielność decyzji dziecka a{'\u00A0'}kontrolą z
                  {'\u00A0'}naszej strony może być praktyczną lekcją poruszania
                  się w{'\u00A0'}ramach określonych zasad. W{'\u00A0'}dorosłym
                  życiu świadomość pewnych granic i{'\u00A0'}znajomość reguł są
                  jednym z{'\u00A0'}kluczowych warunków dobrego funkcjonowania.{' '}
                </p>
                <p>
                  KIESZONKOWE stwarza także doskonałe możliwości do{'\u00A0'}
                  uczenia zależności pomiędzy trudem włożonym w{'\u00A0'}daną
                  czynność a{'\u00A0'}efektem (gratyfikacją). Jestem zdania, że
                  warto traktować wynagradzanie dziecka za{'\u00A0'}wykonanie
                  dodatkowego zadania jako trening umiejętności, a{'\u00A0'}nie
                  pracę. Myślenie o{'\u00A0'}tym w{'\u00A0'}takich kategoriach
                  przełamuje negatywne stereotypy na{'\u00A0'}ten temat.{' '}
                </p>
                <p>
                  Najważniejszym argumentem przemawiającym za{'\u00A0'}
                  włączeniem KIESZONKOWEGO w{'\u00A0'}proces wychowawczy dziecka
                  jest to, że zwiększamy jego szansę na{'\u00A0'}dobrej jakości,
                  samodzielne życie.
                </p>
              </Col>
            </Row>
          </ArticleContainer>
        </ArticleWrapper>

        <UsefulTools
          items={[
            {
              title: 'Ciekawostki',
              subtitle:
                'Rozmowy dzieci z dorosłymi o pieniądzach mają wielką wartość, czyli raport PISA',
              image: data.Tool3,
              onClick: toggleModal,
            },
            {
              title: 'Kolorowanki i rebusy',
              subtitle: 'Kolorowanka',
              image: data.Tool2,
              link: '/parent/Kolorowanka%201.pdf',
            },
            {
              title: 'Gry',
              subtitle: 'Bankosłówka',
              image: data.Tool1,
              link: '/parent/Bankoslowka.pdf',
            },
          ]}
        />
      </ArticleBGWrapper>

      <CuriosityModal isOpen={modal} size="lg" toggle={toggleModal}>
        <ModalBody>
          <CuriosityCloseButton onClick={toggleModal} />
          <div />
          <Img
            fluid={data.CuriosityBigImage.childImageSharp.fluid}
            className="curiosityImage"
          />
          <Img
            fluid={data.CuriosityBigImageMobile.childImageSharp.fluid}
            className="curiosityImageMobile"
          />
        </ModalBody>
      </CuriosityModal>
    </Layout>
  )
}

export default Page
